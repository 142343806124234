const menu = [
  {
    header: 'Central de informações',
    name: 'central_de_informacao',
    profile: ['super_admin', 'admin', 'user'],
  },
  {
    title: 'Início',
    route: 'home',
    name: 'home',
    icon: 'HomeIcon',
    profile: ['super_admin', 'admin', 'user', 'secretary'],
  },
  {
    title: 'Acompanhar Importações',
    name: 'promotions.import.log',
    route: 'promotions.import.log',
    icon: 'ActivityIcon',
    profile: ['super_admin'],
  },
  {
    title: 'Cronograma',
    route: 'promotions.timeline',
    name: 'timeline',
    icon: 'CalendarIcon',
    profile: ['super_admin', 'admin', 'user'],
  },
  {
    title: 'Analytics',
    route: 'promotions.analytics',
    name: 'promotions.analytics',
    icon: 'PieChartIcon',
    profile: ['super_admin', 'admin'],
  },
  {
    header: 'Cadastros',
    name: 'cadastros',
    profile: ['super_admin', 'admin', 'user', 'secretary'],
  },
  {
    title: 'Participantes',
    route: 'participants.list',
    name: 'participants.list',
    icon: 'UserIcon',
    profile: ['super_admin', 'admin', 'user', 'secretary'],
  },
  {
    title: 'Descadastros',
    route: 'unsubscribe-requests.list',
    name: 'unsubscribe-requests.list',
    icon: 'AlertTriangleIcon',
    profile: ['super_admin', 'admin', 'user'],
  },
  {
    title: 'Escolher Sorteio',
    route: 'change-promotion.index',
    name: 'change-promotion.index',
    icon: 'CheckSquareIcon',
    profile: ['super_admin', 'admin', 'user', 'secretary'],
  },
  {
    title: 'Lista de Inelegíveis',
    route: 'ineligible-list.list',
    name: 'ineligible-list.list',
    icon: 'ListIcon',
    profile: ['super_admin', 'admin', 'user'],
  },
  {
    header: 'Sorteios',
    name: 'sorteios_cadastros',
    profile: ['super_admin', 'admin', 'user', 'secretary'],
  },
  {
    title: 'Processar Sorteio',
    name: 'processar_sorteios',
    route: 'process.prizedraw',
    icon: 'AwardIcon',
    profile: ['super_admin', 'admin', 'user'],
  },
  {
    title: 'Enviar Cupons',
    name: 'enviar_cupons',
    route: 'admin.coupons',
    icon: 'FileIcon',
    rule: 'promotion_is_coupon',
    profile: ['secretary'],
  },
  {

    header: 'Adminstração do Sistema',
    name: 'admnistracao_sorteio',
    profile: ['super_admin', 'admin'],
  },
  {
    title: 'Usuários',
    name: 'users.list',
    route: 'users.list',
    icon: 'UserIcon',
    profile: ['super_admin', 'admin'],
  },
  {
    title: 'Clientes',
    name: 'clients.list',
    route: 'clients.list',
    faicon: 'fa-regular fa-building',
    profile: ['super_admin'],
  },
  {
    title: 'Promoções',
    route: 'promotions.list',
    name: 'promotions.list',
    icon: 'AwardIcon',
    profile: ['super_admin'],
  },
  {
    title: 'Sorteios',
    route: 'prize-draw.list',
    name: 'prize-draw.list',
    icon: 'StarIcon',
    profile: ['super_admin'],
  },
  {
    title: 'Sistema',
    route: 'system-settings.list',
    name: 'system-settings.list',
    icon: 'SettingsIcon',
    profile: ['super_admin'],
  },
]

export default menu
